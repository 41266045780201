import cn from 'classnames';
import React from 'react';

import { AudioPlayer } from './components/audio-player';
import type { PlayerProgress } from './components/player-controls/player-controls';
import { VideoPlayer } from './components/video-player';
import styles from './player.module.scss';
import { PlayerCurrentTime } from './use-player';

export interface SharedPlayerProps {
  file: string;
  currentTime?: PlayerCurrentTime;
  isSticky?: boolean;
  onProgressChange?: (progress: PlayerProgress) => void;
}

export interface PlayerProps extends SharedPlayerProps {
  className?: string;
}

export function Player({ onProgressChange, className, file, currentTime, isSticky }: PlayerProps) {
  const classes = cn(styles.player, className);

  if (file.endsWith('.mp3')) {
    return (
      <AudioPlayer
        onProgressChange={onProgressChange}
        className={classes}
        file={file}
        currentTime={currentTime}
        isSticky={isSticky}
      />
    );
  }

  return (
    <VideoPlayer
      onProgressChange={onProgressChange}
      className={classes}
      file={file}
      currentTime={currentTime}
      isSticky={isSticky}
    />
  );
}

