import { useState, useCallback } from 'react';

export interface WithModalProps {
  visible: boolean;
  toggleModal: () => void;
}

export const useModal = (): WithModalProps => {
  const [visible, setVisible] = useState(false);

  const toggleModal = useCallback(() => {
    setVisible((currentVisible) => !currentVisible);
  }, []);

  return {
    visible,
    toggleModal,
  };
};

