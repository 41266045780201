import cn from 'classnames';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { routes } from 'api/routes';

import { Alert } from 'shared/alert';
import { Spin } from 'shared/spin';

import { AuthLayout } from 'components/auth-layout';

import { getResponseError } from 'helpers/response';

import { useDocumentTitle } from 'hooks/document-title';

import styles from './verify-email.page.module.scss';
import { VerifyEmailRequest, useVerifyEmailMutation } from './verify-email.service';

export interface VerifyEmailPageProps {
  className?: string;
}

export function VerifyEmailPage({ className }: VerifyEmailPageProps) {
  const classes = cn(styles.verifyEmailPage, className);
  useDocumentTitle('Подтвердить email');
  const navigate = useNavigate();
  const [getSearchParams] = useSearchParams();
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    setError(null);
    const sendVerifyEmailRequest = async () => {
      const values: VerifyEmailRequest = {
        id: getSearchParams.get('id') || '',
        hash: getSearchParams.get('hash') || '',
        expires: getSearchParams.get('expires') || '',
        signature: getSearchParams.get('signature') || '',
      };

      try {
        await verifyEmail(values).unwrap();
        navigate(routes.profile(), { replace: true, state: { isFirstVisit: true } });
      } catch (error) {
        setError(getResponseError(error));
      }
    };

    sendVerifyEmailRequest();
  }, []);

  if (isLoading) {
    return <Spin className={styles.spin} />;
  }

  return (
    <AuthLayout className={classes} hideHeaderLinks={true}>
      {error && <Alert message={error} type='error' />}
    </AuthLayout>
  );
}

