import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React from 'react';

import { Spin } from 'shared/spin';

import { SelectImage } from 'components/select-image';

import { useModal } from 'hooks/modal';

import { Image, ImageRelatedType } from 'models/image';

import styles from './editable-image.module.scss';

export interface EditableImageProps {
  className?: string;
  isLoading: boolean;
  image?: Image | null;
  type: ImageRelatedType;
  onAdd: (image: Image) => void;
  onRemove: () => void;
  size?: 'small' | 'large';
}

export function EditableImage({ className, isLoading, image, type, onAdd, onRemove, size }: EditableImageProps) {
  const classes = cn(styles.editableImage, className, {
    [styles.editableImage_large]: size === 'large',
    [styles.editableImage_small]: size === 'small',
  });

  if (image) {
    return <EditableImageWithImage className={classes} isLoading={isLoading} image={image} onRemove={onRemove} />;
  }

  return <EditableImageWithoutImage className={classes} isLoading={isLoading} type={type} onAdd={onAdd} />;
}

interface EditableImageWithImageProps {
  className: string;
  isLoading: boolean;
  image: Image;
  onRemove: () => void;
}

function EditableImageWithImage({ className, isLoading, image, onRemove }: EditableImageWithImageProps) {
  return (
    <div className={className}>
      {isLoading ? (
        <Spin className={styles.editableImage__spin} />
      ) : (
        <>
          <img className={styles.editableImage__image} src={image.url} alt='' />
          <div className={styles.editableImage__action}>
            <DeleteOutlined className={styles.editableImage__icon} onClick={onRemove} />
          </div>
        </>
      )}
    </div>
  );
}

interface EditableImageWithoutImageProps {
  className: string;
  type: ImageRelatedType;
  isLoading: boolean;
  onAdd: (image: Image) => void;
}

function EditableImageWithoutImage({ className, isLoading, type, onAdd }: EditableImageWithoutImageProps) {
  const { visible, toggleModal } = useModal();

  return (
    <>
      <div className={className}>
        {isLoading ? (
          <Spin className={styles.editableImage__spin} />
        ) : (
          <div
            className={cn(
              styles.editableImage__action,
              styles.editableImage__action_pointer,
              styles.editableImage__action_empty,
            )}
            onClick={toggleModal}
          >
            <PlusOutlined className={styles.editableImage__icon} />
          </div>
        )}
      </div>
      <SelectImage type={type} onSelect={onAdd} visible={visible} toggleModal={toggleModal} />
    </>
  );
}

