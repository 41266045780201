import { CloseOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import React, { useLayoutEffect, useState } from 'react';
import { Transforms } from 'slate';
import { ReactEditor, RenderElementProps, useSlateStatic } from 'slate-react';

import { Affix } from 'shared/affix';
import { Button } from 'shared/button';
import { modalConfirm } from 'shared/modal';
import { Title } from 'shared/typography';

import { InterviewWrapElement } from 'components/editor/editor.types';
import { Player, PlayerCurrentTime, PlayerProgress } from 'components/player';

import { HEADER_HEIGHT } from 'helpers/constants';

import { useModal } from 'hooks/modal';

import { Transcription, translateTranscriptionTypeIntoAdverb } from 'models/transcription';

import { TranscriptionSettings } from '../transcription-settings';
import styles from './interview-wrap.module.scss';

export type InterviewWrapProps = RenderElementProps & {
  element: InterviewWrapElement;
  onRemoveTranscription?: (transcription: Transcription) => void;
  onDownloadTranscription?: (transcription: Transcription) => void;
  isDownloadingTranscription?: boolean;
  onProgressChange?: (progress: PlayerProgress) => void;
  currentTime?: PlayerCurrentTime;
};

export function InterviewWrap({
  children,
  attributes,
  element,
  onRemoveTranscription,
  onDownloadTranscription,
  isDownloadingTranscription,
  onProgressChange,
  currentTime,
}: InterviewWrapProps) {
  const { visible, toggleModal } = useModal();
  const [isSticky, setIsSticky] = useState<boolean | undefined>(undefined);
  const [hasVerticalScroll, setHasVerticalScroll] = useState<boolean>(false);

  const editor = useSlateStatic();

  useLayoutEffect(() => {
    setHasVerticalScroll(document.body.scrollHeight > document.body.clientHeight);
  }, []);

  const onChangeStatusAndType = (status: Transcription['transcribeStatus'], type: Transcription['type']) => {
    if (!element.meta?.transcription) {
      return;
    }

    const path = ReactEditor.findPath(editor, element);
    const newProperties: Partial<InterviewWrapElement> = {
      meta: {
        transcription: {
          ...element.meta.transcription,
          transcribeStatus: status,
          type,
        },
      },
    };
    Transforms.setNodes(editor, newProperties, { at: path });
  };

  const onChangeTranscriptionTitle = (value: string) => {
    if (!element.meta?.transcription) {
      return;
    }

    const path = ReactEditor.findPath(editor, element);
    const newProperties: Partial<InterviewWrapElement> = {
      meta: {
        transcription: {
          ...element.meta.transcription,
          name: value,
        },
      },
    };
    Transforms.setNodes(editor, newProperties, { at: path });
  };

  const handleRemove = () => {
    modalConfirm({
      title: 'Вы уверены, что хотите удалить запись?',
      content: 'Транскрипт записи также будет удален',
      okText: 'Удалить',
      okButtonProps: {
        danger: true,
      },
      onOk: () => {
        if (element.meta?.transcription && onRemoveTranscription) {
          onRemoveTranscription(element.meta.transcription);
        }
      },
    });
  };

  return (
    <div className={styles.transcription} {...attributes}>
      <div contentEditable='false'>
        <div className={styles.transcription__header}>
          <div className={styles.transcription__headerInfo}>
            <Button
              className={styles.transcription__removeButton}
              type='link'
              icon={<CloseOutlined />}
              onClick={handleRemove}
            />
            <Title
              className={styles.transcription__name}
              level={4}
              editable={{
                triggerType: ['text'],
                onChange: onChangeTranscriptionTitle,
              }}
            >
              {element.meta?.transcription?.name}
            </Title>
            <Affix offsetTop={HEADER_HEIGHT} onChange={setIsSticky}>
              <Player
                onProgressChange={onProgressChange}
                className={styles.transcription__player}
                file={element.meta?.transcription?.url || ''}
                // Не надо уменьшать высоту видеоплеера если изначально нет вертикального скролла на странице
                // https://gitlab.com/qulap_dev/qulap_frontend/-/issues/95
                isSticky={hasVerticalScroll && isSticky}
                currentTime={currentTime}
              />
            </Affix>
          </div>
        </div>
        {element.meta?.transcription?.transcribeStatus === 'not_started' && (
          <Button type='default' onClick={toggleModal}>
            Транскрибировать
          </Button>
        )}
        <div>
          {element.meta?.transcription?.transcribeStatus === 'in_progress' && (
            <>
              <LoadingOutlined className={styles.transcription__icon} />
              Транскрибируем{' '}
              {element.meta?.transcription?.type
                ? translateTranscriptionTypeIntoAdverb(element.meta?.transcription.type).toLowerCase()
                : ''}
              . Можете закрыть страницу - мы пришлем Вам уведомление на email, когда всё будет готово.
            </>
          )}
          {element.meta?.transcription?.transcribeStatus === 'error' && (
            <>
              <WarningOutlined className={styles.transcription__icon} /> Что-то пошло не так...
            </>
          )}
        </div>
        <TranscriptionSettings
          id={element.meta?.transcription?.id}
          price={element.meta?.transcription?.price}
          visible={visible}
          toggleModal={toggleModal}
          onChangeStatusAndType={onChangeStatusAndType}
        />
      </div>
      {element.meta?.transcription?.transcribeStatus === 'done' && (
        <>
          <div contentEditable='false' className={styles.transcription__info}>
            <Title level={5} className={styles.transcription__infoTitle}>
              Транскрипт
            </Title>
            {onDownloadTranscription && (
              <Button
                type='default'
                size='middle'
                onClick={() => {
                  if (element.meta?.transcription) {
                    onDownloadTranscription(element.meta.transcription);
                  }
                }}
                loading={isDownloadingTranscription}
              >
                Скачать docx
              </Button>
            )}
          </div>
          <div className={styles.transcription__content}>{children}</div>
        </>
      )}
    </div>
  );
}

