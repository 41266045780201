import { MailOutlined, LockOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { routes } from 'api/routes';

import { Button } from 'shared/button';
import { Form, FormItem } from 'shared/form';
import { Input, InputPassword } from 'shared/input';
import { Space } from 'shared/space';
import { Title, Paragraph } from 'shared/typography';

import { AuthLayout } from 'components/auth-layout';

import { NavigateFrom } from 'helpers/navigate';
import { getResponseError } from 'helpers/response';
import { validateRules } from 'helpers/validate-rules';

import { useDocumentTitle } from 'hooks/document-title';

import styles from './login.page.module.scss';
import { LoginRequest, useLoginMutation } from './login.service';

export interface LoginPageProps {
  className?: string;
}

export function LoginPage({ className }: LoginPageProps) {
  const classes = cn(styles.loginPage, className);
  useDocumentTitle('Авторизация');
  const navigate = useNavigate();
  const location = useLocation();
  const [login, { isLoading }] = useLoginMutation();
  const [error, setError] = useState<null | string>(null);

  const onFinish = async (values: LoginRequest) => {
    setError(null);
    try {
      await login(values).unwrap();
      navigate((location.state as NavigateFrom)?.from.pathname || routes.home());
    } catch (error) {
      setError(getResponseError(error));
    }
  };

  return (
    <AuthLayout
      className={classes}
      footerSlot={
        <Paragraph size='small'>
          Выполняя вход, вы подтверждаете, что принимаете нашу{' '}
          <Link to={routes.privacy()}>Политику&nbsp;конфиденциальности</Link> и{' '}
          <Link to={routes.termsOfService()}>Условия&nbsp;использования</Link> платформы Qulap
        </Paragraph>
      }
    >
      <Title>С возвращением!</Title>
      <Form
        onFinish={onFinish}
        autoComplete='off'
        error={error}
        actionSlot={
          <Space>
            <Button htmlType='submit' loading={isLoading}>
              Войти
            </Button>
            <Link to={routes.forgotPassword()}>
              <Button type='link' href={routes.forgotPassword()}>
                Восстановить пароль
              </Button>
            </Link>
          </Space>
        }
      >
        <FormItem name='email' rules={[validateRules.required('email'), validateRules.email()]}>
          <Input addonBefore={<MailOutlined />} />
        </FormItem>
        <FormItem name='password' rules={[validateRules.required('пароль')]}>
          <InputPassword addonBefore={<LockOutlined />} />
        </FormItem>
      </Form>
    </AuthLayout>
  );
}

