import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { routes } from 'api/routes';

import { ErrorBoundary } from 'components/error-boundary';
import { Help } from 'components/help';
import { OnboardingProvider } from 'components/onboarding';
import { PrivateRoute } from 'components/private-route';
import { PublicOnlyRoute } from 'components/public-only-route';

import { BillingPage } from 'features/billing';
import { CookiePolicyPage } from 'features/cookie-policy';
import { ForgotPasswordPage } from 'features/forgot-password';
import { InterviewPage } from 'features/interview';
import { InterviewsPage } from 'features/interviews';
import { LoginPage } from 'features/login';
import { MarketplacePage } from 'features/marketplace/marketplace.page';
import { NotFound } from 'features/not-found';
import { PaymentStatusPage } from 'features/payment-status';
import { PrivacyPage } from 'features/privacy';
import { ProfilePage } from 'features/profile';
import { ProjectPage } from 'features/project';
import { ProjectsPage } from 'features/projects';
import { ReportPage, ReportSharedPage } from 'features/report';
import { ResetPasswordPage } from 'features/reset-password';
import { SignUpPage } from 'features/sign-up';
import { TermsOfServicePage } from 'features/terms-of-service';
import { VerifyEmailPage } from 'features/verify-email';

import styles from './app.module.scss';

export function App() {
  return (
    <div data-onboarding='app' className={styles.app}>
      <ErrorBoundary>
        <Router>
          <OnboardingProvider>
            <Routes>
              {/* Исключительно публичные роутеры */}
              <Route path={routes.login()} element={<PublicOnlyRoute element={<LoginPage />} />} />
              <Route path={routes.signUp()} element={<PublicOnlyRoute element={<SignUpPage />} />} />
              <Route path={routes.forgotPassword()} element={<PublicOnlyRoute element={<ForgotPasswordPage />} />} />
              <Route path={routes.resetPassword()} element={<PublicOnlyRoute element={<ResetPasswordPage />} />} />
              <Route path={routes.verifyEmail()} element={<PublicOnlyRoute element={<VerifyEmailPage />} />} />

              {/* Приватные роутеры */}
              <Route
                path={routes.home()}
                element={<PrivateRoute element={<Navigate to={routes.projects()} replace={true} />} />}
              />
              <Route path={routes.profile()} element={<PrivateRoute element={<ProfilePage />} />} />
              <Route path={routes.projects()} element={<PrivateRoute element={<ProjectsPage />} />} />
              <Route path={routes.project()} element={<PrivateRoute element={<ProjectPage />} />} />
              <Route path={routes.interviews()} element={<PrivateRoute element={<InterviewsPage />} />} />
              <Route path={routes.interview()} element={<PrivateRoute element={<InterviewPage />} />} />
              <Route path={routes.report()} element={<PrivateRoute element={<ReportPage />} />} />
              <Route path={routes.paymentStatus()} element={<PrivateRoute element={<PaymentStatusPage />} />} />
              <Route path={routes.billing()} element={<PrivateRoute element={<BillingPage />} />} />

              {/* Публичные роутеры */}
              <Route path={routes.sharedReport()} element={<ReportSharedPage />} />
              <Route path={routes.privacy()} element={<PrivacyPage />} />
              <Route path={routes.termsOfService()} element={<TermsOfServicePage />} />
              <Route path={routes.cookiePolicy()} element={<CookiePolicyPage />} />
              <Route path={routes.marketplace()} element={<MarketplacePage />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </OnboardingProvider>
        </Router>
        <Help className={styles.app__help} />
      </ErrorBoundary>
    </div>
  );
}
