import { Popover } from 'antd';
import React, { PropsWithChildren, ReactNode, Ref, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Editor, Range } from 'slate';
import { useFocused, useSlate } from 'slate-react';

import styles from './editor.module.scss';
import Questions, { IQuestionsList } from './editor.questions';

export type IQuestionItemProps = IQuestionsList;

export function QuestionsPopup({ questionsList, setQuestion }: IQuestionItemProps) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const editor = useSlate();
  const inFocus = useFocused();

  const hidePopup = () => {
    const el = ref.current;

    if (!el) return;

    el.removeAttribute('style');
    el.style.display = 'none';
    setIsPopoverVisible(false);
  };

  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    if (!selection || !inFocus || Range.isCollapsed(selection) || Editor.string(editor, selection) === '') {
      hidePopup();
      return;
    }

    const listener = () => {
      const domSelection = window.getSelection();

      const parentElement = domSelection?.focusNode?.parentElement;

      if (!(parentElement && parentElement?.dataset?.['slateString'])) {
        return;
      }

      if (domSelection && domSelection.rangeCount) {
        const domRange = domSelection.getRangeAt(0);
        const rect = domRange.getBoundingClientRect();
        el.style.display = 'block';
        el.style.position = 'absolute';
        el.style.opacity = '1';
        el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
        el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;

        setIsPopoverVisible(true);
      }
    };

    window.addEventListener('mouseup', listener);

    return () => {
      window.removeEventListener('mouseup', listener);
    };
  });

  const onSetQuestion = (id: string, color: string) => () => {
    hidePopup();
    setQuestion(id, color)();
  };

  return (
    <Portal>
      <Menu
        ref={ref}
        onMouseDown={(e: MouseEvent) => {
          e.preventDefault();
        }}
      >
        <Popover
          overlayClassName={styles.questionsPopover}
          onVisibleChange={() => setIsPopoverVisible(false)}
          placement='rightTop'
          visible={isPopoverVisible}
          destroyTooltipOnHide={true}
          trigger='click'
          content={<Questions questionsList={questionsList} setQuestion={onSetQuestion} />}
        />
      </Menu>
    </Portal>
  );
}

interface PortalType {
  domElement?: Element;
  children: ReactNode;
}

export const Portal = ({ domElement, children }: PortalType) => {
  const element = domElement || document.body;
  return createPortal(children, element);
};

interface BaseProps {
  className: string;
  [key: string]: unknown;
}

export const Menu = React.forwardRef(function menu(
  { className, ...props }: PropsWithChildren<BaseProps>,
  ref: Ref<HTMLDivElement>,
) {
  return <div {...props} ref={ref} className={className} />;
});

