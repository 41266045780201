import { paths } from 'api/paths';

import { api } from 'store/service';

export interface VerifyEmailRequest {
  id: string;
  hash: string;
  expires: string;
  signature: string;
}

export const VerifyEmailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    verifyEmail: builder.mutation<void, VerifyEmailRequest>({
      query: (credentials) => ({
        url: paths.verifyEmail(credentials.id, credentials.hash),
        params: {
          expires: credentials.expires,
          signature: credentials.signature,
        },
      }),
      invalidatesTags: ['User', 'UserPermissions'],
    }),
  }),
  overrideExisting: false,
});

export const { useVerifyEmailMutation } = VerifyEmailApi;

